import { EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Component } from '@angular/core';
import { FarmDto } from '@shared/service-proxies/service-proxies';
import { latLng, tileLayer, marker, icon, Map } from 'leaflet';

@Component({
    selector: 'farm-card',
    templateUrl: './farm-card.component.html',
    styleUrls: ['./farm-card.component.scss']
})
export class FarmCardComponent implements OnInit {
    @Input('farm') farm: FarmDto;
    @Output('edit') edit: EventEmitter<void> = new EventEmitter<void>();
    @Output('remove') remove: EventEmitter<void> = new EventEmitter<void>();
    options = {};
    mapOptions: any;
    map: Map;

    ngOnInit() {
        this.updateMapOptions();
    }

    onMapReady(map: Map) {
        this.map = map;
        this.map.zoomControl.remove();
        this.map.touchZoom.disable();
        this.map.doubleClickZoom.disable();
        this.map.scrollWheelZoom.disable();
        this.map.boxZoom.disable();
        this.map.keyboard.disable();
        this.map.dragging.disable();
    }

    getAddress() {
        let address = '';
        if (this.farm.address.address1) {
            address += `${this.farm.address.address1}`;
        }
        if (this.farm.address.address2) {
            address += ` ${this.farm.address.address2}`;
        }
        if (this.farm.address.city) {
            address += `, ${this.farm.address.city}`;
        }
        if (this.farm.address.county) {
            address += `, ${this.farm.address.county}`;
        }
        if (this.farm.address.state) {
            address += `, ${this.farm.address.state}`;
        }
        if (this.farm.address.country) {
            address += `, ${this.farm.address.country}`;
        }

        return address;
    }

    private updateMapOptions() {
        const layers: any[] = [
            tileLayer('https://www.google.com/maps/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
                maxZoom: 18,
                subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                detectRetina: true,
            }),
            marker([this.farm.address.latitude, this.farm.address.longitude], {
                icon: icon({
                    iconSize: [32, 48],
                    iconUrl: './assets/maps/marker_farm.png',
                }),
            })
        ];
        this.mapOptions = {
            layers,
            zoom: 18,
            center: latLng(
                this.farm.address.latitude,
                this.farm.address.longitude
            ),
        };
    }

    callEdit() {
        this.edit.emit();
    }

    callRemove() {
        this.remove.emit();
    }
}