import { Injectable, Injector } from '@angular/core';
import { MatPaginatorIntl as MatPaginatorIntl } from '@angular/material/paginator';
import { LocalizationService } from 'abp-ng2-module';
import { AppConsts } from '@shared/AppConsts';

@Injectable()
export class MatPaginatorIntlTranslate extends MatPaginatorIntl {
  private localizationSourceName =
    AppConsts.localization.defaultLocalizationSourceName;
  private localization: LocalizationService;

  constructor(injector: Injector) {
    super();
    this.localization = injector.get(LocalizationService);
    this.nextPageLabel = this.l('NextPage');
    this.previousPageLabel = this.l('PreviousPage');
    this.firstPageLabel = this.l('FirstPage');
    this.lastPageLabel = this.l('LastPage');
    this.itemsPerPageLabel = this.l('ItemsPerPage');
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.l('Of')} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.l('Of')} ${length}`;
  }

  private l(key: string, ...args: any[]): string {
    let localizedText = this.localization.localize(
      key,
      this.localizationSourceName
    );

    if (!localizedText) {
      localizedText = key;
    }

    if (!args || !args.length) {
      return localizedText;
    }

    args.unshift(localizedText);
    return abp.utils.formatString.apply(this, args);
  }
}
