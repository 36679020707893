<div class="w-100 my-3 shadow">
  <div class="px-3 w-100 title-content">
    <a [routerLink]="'details/' + farm.id">{{farm.name}}</a>
    <div class="actions">
      <a mat-icon-button matTooltip="{{ 'Edit' | localize }}" matTooltipPosition="above" [routerLink]="['edit/' + farm.id]">
        <mat-icon>edit</mat-icon>
      </a>
      <button mat-icon-button color="warn" matTooltip="{{ 'Delete' | localize }}" matTooltipPosition="above"
        (click)="callRemove()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  <div class="famr-content w-100">
    <div id="map" #map (leafletMapReady)="onMapReady($event)" class="map-content w-100" leaflet
      [leafletOptions]="mapOptions">
    </div>
    <div class="address p-2 w-100">
      <mat-icon>place</mat-icon> {{getAddress()}}
    </div>
  </div>
  <div class="w-100 p-2">
    <mat-chip-list cdkDropList cdkDropListOrientation="horizontal">
      <mat-chip>
        <div class="chip-content">
          <i class="fas fa-leaf"></i> {{farm.cropsCount}}
        </div>
      </mat-chip>
      <mat-chip>
        <div class="chip-content">
          <mat-icon>store</mat-icon> {{farm.controlPointCount}}
        </div>
      </mat-chip>
      <mat-chip>
        <div class="chip-content">
          <mat-icon>api</mat-icon> {{farm.area}} ha
        </div>
      </mat-chip>
    </mat-chip-list>
  </div>
</div>