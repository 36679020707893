import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from 'abp-ng2-module';

import * as ApiServiceProxies from './service-proxies';

@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.FarmServiceProxy,
        ApiServiceProxies.CropServiceProxy,
        ApiServiceProxies.ControlPointServiceProxy,
        ApiServiceProxies.SpecieServiceProxy,
        ApiServiceProxies.VarietyServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.SeasonServiceProxy,
        ApiServiceProxies.ContainerServiceProxy,
        ApiServiceProxies.FolioServiceProxy,
        ApiServiceProxies.MeasureUnitServiceProxy,
        ApiServiceProxies.TransportUnitServiceProxy,
        ApiServiceProxies.ProductionCurveServiceProxy,
        ApiServiceProxies.EstimateServiceProxy,
        ApiServiceProxies.DispatchDestinationServiceProxy,
        ApiServiceProxies.ContainerTypeServiceProxy,
        ApiServiceProxies.HarvestTypeServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
